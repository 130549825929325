import * as React from "react";

const ProductivityData = [
    {
        id: 2,
        title: `DrivosityEDGE`,
        subtitle: 'Give Your Drivers a Competitive Edge',
        content: (
            <>
                DrivosityEDGE enhances your first-party delivery operations by going beyond safety, providing your drivers with real-time data and insights to optimize every delivery. From improving productivity to elevating customer satisfaction, DrivosityEDGE empowers your team to deliver better results with every trip.
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `Boost Efficiency with Real-Time Transparency`,
        subtitle:``,
        content: (
            <>
                <div className='paragraph'>
                    Efficiency thrives on transparency. DrivosityEDGE offers full visibility into the entire delivery process, enabling drivers to manage their time more effectively and improve their performance. With key metrics like Customer Wait Time, Guest Feedback, and Service Measures, drivers receive real-time feedback to enhance their delivery experience.
                </div>
                <div className='paragraph'>
                    With DrivosityEDGE, your drivers can:
                </div>
                <div className="points">Reduce customer wait times by an average of 5 minutes</div>
                <div className="points">Reduce extended stops by up to 18%</div>
                <div className="points">Complete more deliveries per hour, making each trip more efficient</div>
                <div className="points">Decrease reliance on third-party services by optimizing internal processes</div>
                <div className="points">Ensure on-time deliveries in optimal condition, keeping customers satisfied and loyal</div>
                <div className="points">Leverage HustleTime™ metrics to maximize productivity and profitability</div>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `Customizable Reports for Deeper Insights`,
        subtitle:``,
        content: (
            <div className='paragraph'>
                DrivosityEDGE’s customizable reporting features provide you with detailed insights into driver productivity, performance, and efficiency. Reports can be tailored to specific metrics over any given period, allowing you to sort data and identify areas for improvement.
            </div>
        ),
        subcontent: (
            <div className='paragraph pt-14 gap-y-8 md:gap-y-4'>
                Our reporting tools give you the data you need to guide drivers toward better performance, ensuring that each delivery is faster and more efficient.
            </div>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        subtitle:``,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">Trip Review</span>
                <span className="flex justify-center text-center text-small font-extralight">Full transparency into the delivery experience.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        subtitle:``,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">Incident Report</span>
                <span className="flex justify-center text-center text-small font-extralight">Show where you excel and where you can improve productivity.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        subtitle:``,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">Efficiency</span>
                <span className="flex justify-center text-center text-small font-extralight">Displayed labor statistics to manage your business.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `IN-DEPTH REPORTING FEATURES`,
        subtitle:``,
        content: (
            <>
                <span className="flex justify-center text-center text-2xl font-extralight pb-4">DriveScore&reg;</span>
                <span className="flex justify-center text-center text-small font-extralight">Never lose sight of your safety standards.</span>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `What Are HustleTime™ Metrics?`,
        subtitle:``,
        content: (
            <>
                <div className='paragraph'>
                    HustleTime™ is a core feature of DrivosityEDGE, designed to help drivers elevate their productivity by tracking key moments in the delivery process that they control. These metrics offer real-time insights into how drivers are managing their time, helping them pinpoint opportunities to improve efficiency.
                </div>
                <div className='paragraph'>
                    HustleTime™ tracks:
                </div>
                <div className="points">
                    Seatbelt Time: The time between accepting a delivery and leaving the store
                </div>
                <div className="points">
                    At-The-Door Time: The time spent at the customer’s location to ensure delivery satisfaction
                </div>
                <div className="points">
                    Lot Time: The time it takes for the driver to return to the store and check in after completing a delivery
                </div>
                <div className='paragraph'>
                    By monitoring these key points, drivers can minimize delays and increase deliveries per hour, ultimately boosting income and overall performance.
                </div>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: `Why DrivosityEDGE?`,
        subtitle:``,
        content: (
            <>
                <div className='paragraph'>
                    DrivosityEDGE is a comprehensive solution for improving delivery performance and customer satisfaction. With advanced tracking, real-time feedback, and customizable reports, you’ll equip your drivers with everything they need to excel on every delivery.
                </div>
                <div className='paragraph'>
                    Key benefits include:
                </div>
                <div className="points">
                    Increased driver productivity, with up to 12-24% more deliveries per hour
                </div>
                <div className="points">
                    Reduced dependence on third-party services, saving both time and money
                </div>
                <div className="points">
                    Improved driver retention by fostering a culture of engagement and efficiency
                </div>
                <div className="points">
                    Lower insurance costs thanks to enhanced safety practices
                </div>
            </>
        ),
        location: `/productivity`,
        data: null,
    },
    {
        id: 2,
        title: ``,
        subtitle:``,
        content: (
            <>
                Want to learn more about DrivosityEDGE?
            </>
        ),
        location: `/productivity`,
        data: null,
    },
];

export {ProductivityData};
